<template>
   <div class="container" id="print-final">
      <div class="row justify-content-md-center scan-wizard-hdr mt-4">
        <div class="col-12 col-lg-8 mt-4" v-if="$store.state.didValidateAllShares">
          <h4>Congratulations</h4>
          <p>You have successfully verified that your QR codes have printed correctly and can be used to recover your confidential information</p>
            <p class="mt-3">Please distribute your QR Codes, each to a remote location, to ensure maximum security</p>
          <hr>
          <p class="mt-3 mb-3">Tip: Keep your QR Codes in folders or flat protective enclosures to help protect against UV light to ensure their longevity.
            <span class="font-weight-bold">DO NOT FOLD</span> the prints; it may compromise your ability to recover in the future.
          </p>
        </div>
        <div class="col-12 col-lg-8 mt-4" v-else>
            <h4>Congratulations:</h4>
            <p>
               You have successfully created a QR code set from your confidential information. We urge you to validate your QR codes prior to storing them. By validating, you can be assured that your QR codes can be scanned successfully during future recoveries.
            </p>

            <p>
               Please distribute your QR Codes after validating them, each to a remote location to ensure maximum security.
            </p>
        </div>
      </div>

      <div class="row justify-content-center" v-if="isDemo || didHaveActiveCredit">
        <div class="col-10 col-lg-8 text-center mt-4 tweet-request">
            <div v-html="getCongratsTweetHtml()"></div>

            <div v-if="!lastSavedTwitterHandle && isTweetRewardsActive">
               <h5>Enter Your Twitter Handle</h5>
               <form class="form-inline mt-3 justify-content-center">
                  <input type="text" class="form-control mb-2 mr-sm-2" v-model="twitterHandle" id="inlineFormInputName2" placeholder="Twitter Handle">
                  <button type="submit" @click="$event.preventDefault(); setTwitterHandle()" class="btn btn-primary mb-2" id="referal-btn">Apply</button>
                  <p class="text-danger">{{twitterHandleSaveErrorMsg}}</p>
               </form>
            </div>

            <div v-else>
               <button class="tweet-button" @click="tweetRedirect"><img height=20 src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2072%2072%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h72v72H0z%22%2F%3E%3Cpath%20class%3D%22icon%22%20fill%3D%22%23fff%22%20d%3D%22M68.812%2015.14c-2.348%201.04-4.87%201.744-7.52%202.06%202.704-1.62%204.78-4.186%205.757-7.243-2.53%201.5-5.33%202.592-8.314%203.176C56.35%2010.59%2052.948%209%2049.182%209c-7.23%200-13.092%205.86-13.092%2013.093%200%201.026.118%202.02.338%202.98C25.543%2024.527%2015.9%2019.318%209.44%2011.396c-1.125%201.936-1.77%204.184-1.77%206.58%200%204.543%202.312%208.552%205.824%2010.9-2.146-.07-4.165-.658-5.93-1.64-.002.056-.002.11-.002.163%200%206.345%204.513%2011.638%2010.504%2012.84-1.1.298-2.256.457-3.45.457-.845%200-1.666-.078-2.464-.23%201.667%205.2%206.5%208.985%2012.23%209.09-4.482%203.51-10.13%205.605-16.26%205.605-1.055%200-2.096-.06-3.122-.184%205.794%203.717%2012.676%205.882%2020.067%205.882%2024.083%200%2037.25-19.95%2037.25-37.25%200-.565-.013-1.133-.038-1.693%202.558-1.847%204.778-4.15%206.532-6.774z%22%2F%3E%3C%2Fsvg%3E"/> Tweet #NotYourKeysNotYourCoins</button>
            </div>
         </div>
      </div>

      <PurchaseOffers v-if="isDemo && !$store.state.isOffline" />

      <div v-if="!$store.state.isOffline" class="row justify-content-md-center">
        <div class="col-12 col-lg-6 mt-4">
            <a @click="$event.preventDefault(); $bvModal.show('newsletter-modal');" href="#" class="btn btn-orange btn-lg btn-block" data-toggle="modal" data-target="#newsletter-modal"><i class="bi bi-newspaper" style="padding-right:20px"></i>Newsletter Signup</a>
        </div>
      </div>      

      <div v-if="!$store.state.isOffline" class="row justify-content-center mt-2">
        <div class="col-12 col-lg-6 mt-5" style="margin-bottom:125px">
          <a href="/" @click="$event.preventDefault(); navigateHome();" class="btn btn-outline-primary btn-lg btn-block" style="background:#fff">Home</a>
        </div>
      </div>
      <div v-else class="row justify-content-md-center">
        <div class="col-12 col-lg-6 mt-5" style="margin-bottom:125px">
          <a href="/" @click="$event.preventDefault()" class="btn btn-outline-primary btn-lg btn-block" style="background:#fff; opacity: 0.5;">Home</a>

           <div class="row col justify-content-center">
              <p class="text-danger mt-3" style="text-align: center">You appear to be offline.<br/>
              Please reconnect to the internet to continue.</p>
           </div>
        </div>
      </div>

      <b-modal class="modal fade" id="newsletter-modal" tabindex="-1" role="dialog" aria-labelledby="signupModalLabel" aria-hidden="true" centered hide-footer>
         <div class="modal-body text-center">
            <p>Signup for our newsletter to keep track of updates and new features</p>
            <EmailSignup />
         </div>
      </b-modal>
    </div>
</template>

<script>
import EmailSignup from "@/components/email-signup"
import PurchaseOffers from "@/components/purchase-offers"

export default  {
   components: {
      EmailSignup,
      PurchaseOffers
   },
   mounted() {
      if (!this.isDemo) {
         this.axios
            .get("/api/account-data", { withCredentials: true })
            .then((response) => {
               this.$store.commit("setAccountData", response.data);
            })
            .catch((error) => console.error(error));

         this.twitterHandle = this.$store.state.accountData.twitter_handle;
         this.lastSavedTwitterHandle = this.twitterHandle;
         this.didHaveActiveCredit = this.isDemo || this.$route.query.credit;
         this.$store.commit("setActiveCreditId", 0);
      }
      this.$store.commit("setIsHidingNavigateAway", false);
      if (this.isDemo) {
         if (this.$store.state.didValidateAllShares) {
            window.gtag("event", "PrintFinal2_Demo_WithValidate");
         }
         else {
            window.gtag("event", "PrintFinal2_Demo_NoValidate");
         }
      }  
      else { // not isDemo
         if (this.$store.state.didValidateAllShares) {
            window.gtag("event", "PrintFinal2_Paid_WithValidate");
         }
         else {
            window.gtag("event", "PrintFinal2_Paid_NoValidate");
         }
      }
   },
   data() {
      return {
         didHaveActiveCredit: false,
         isTweetSignupVisible: false,
         isTweetLinkVisible: false,
         twitterHandle: "",
         twitterHandleSaveErrorMsg: "",
         lastSavedTwitterHandle: "",
      }
   },
   computed: {
      isDemo() {
         return location.href.includes("/demo");
      },
      isTweetRewardsActive() {
         var accountData = this.$store.state.accountData;
         if (accountData && accountData.frontend_settings && accountData.frontend_settings.paid_tweet_rewards_toggle) {
            if (this.isDemo) {
               return accountData.frontend_settings.demo_tweet_rewards_toggle.value === "true"
            }
            else {
               return accountData.frontend_settings.paid_tweet_rewards_toggle.value === "true"
            }
         }
         else {
            return false;
         }
      },
   },
   methods: {
      tweetRedirect() {
         let url = this.$store.state.accountData.frontend_settings.congrats_tweeturl.value;
         window.open(url, "_blank");
      },
      setTwitterHandle() {
         this.twitterHandleSaveErrorMsg = "";
         const toSend = {
            "twitterHandle": this.twitterHandle
         }
         let apiEndpoint = this.isDemo ? "/api/update-demo-twitter-handle" : "/api/update-twitter-handle";
         this.axios
            .post(apiEndpoint, toSend, { withCredentials: true })
            .then((response) => {
               if (response.data && response.data.status == "success") {
                  this.lastSavedTwitterHandle = this.twitterHandle;
               }
               else {
                  this.twitterHandle = this.lastSavedTwitterHandle;
                  this.twitterHandleSaveErrorMsg = response.data.status || "Failed to save twitter handle. Please check your handle of the form @twitterhandle, and try again.";
               }
            })
            .catch((error) => console.error(error));

      },
      navigateHome() {
         let homeUrl = this.isDemo ? "/demo" : "/";
         console.log("Navigating to " + homeUrl);
         window.location.assign(homeUrl);
      },
      getCongratsTweetHtml() {
         let result = "";
         if (this.isDemo) {
            if (this.$store.state.didValidateAllShares) {
               result = this.$store.state.accountData.frontend_settings.demo_congrats_tweet_verified_html.value;
            }
            else {
               result = this.$store.state.accountData.frontend_settings.demo_congrats_tweet_unverified_html.value;
            }
         }  
         else { // not isDemo
            if (this.$store.state.didValidateAllShares) {
               result = this.$store.state.accountData.frontend_settings.congrats_tweet_verified_html.value;
            }
            else {
               result = this.$store.state.accountData.frontend_settings.congrats_tweet_unverified_html.value;
            }
         }
         result = result.replace("$AppDomain$", window.location.hostname);
         return result;
      }
   }
}
</script>
