<template>
   <qrscan />
</template>

<script>

export default {
   name: "recover",
   data() {
      return {
      };
   },
   methods: {
   },
   mounted() {
      this.$store.commit("setActiveCreditId", 0);
      console.log("active credit id = " + this.$store.activeCreditId);
      this.$store.commit("setActiveCreditId", "");
      console.log("active credit id = " + this.$store.activeCreditId);
      window.gtag("event", "RecoverPage");
   }
};
</script>
