<template>
   <div>
      <div class="container" id="validate">
         <qrscan @hideWizard="hideWizard()" />

         <div v-if="isWizardVisible" class="row justify-content-md-center mt-5 print-nav-desk">
            <div class="col-12 col-lg-6 d-none d-lg-block text-center">
               <div class="row">
                  <div class="col-6 text-left">
                     <a href="/print-qr" @click="previous($event)"><i class="bi bi-chevron-left"></i>Back</a>
                  </div>
                  <div class="col-6 text-right">
                     <a href="print-final2" @click="$event.preventDefault(); next();">Skip Validation<i class="bi bi-chevron-right"></i></a>
                  </div>
               </div><!--END ROW-->
            </div>
         </div>
      </div><!--END CONTAINER-->

      <div v-if="isWizardVisible" class="container-fluid print-nav fixed-bottom d-lg-none">
         <div class="row">
            <div class="container">
               <div class="row justify-content-md-center mt-1 mb-1">
                  <div class="col-6 col-lg-6 print-nav-btn print-nav-btn-back">
                     <a href="/print-qr" @click="$event.preventDefault(); previous()">Back</a>
                  </div>
                  <div class="col-6 col-lg-6 print-nav-btn print-nav-btn-next">
                     <a href="print-final2" @click="$event.preventDefault(); next();" class="next-button">Skip</a>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: "Validate",
   mounted() {
      this.$store.commit("setIsHidingNavigateAway", true);
      window.gtag("event", "ValidatePage");
   },
   methods: {
      previous() {
         this.$router.push({path: `${this.demoPrefix}print-qr`});
      },
      next() {
         if (this.$route.query.credit) {
            this.$router.push({path: `${this.demoPrefix}print-final2?credit=${this.$route.query.credit}`});
         }
         else {
            this.$router.push({path: `${this.demoPrefix}print-final2`});
         }
      },
      hideWizard() {
         this.isWizardVisible = false;
      }
   },
   data() {
      return {
         isWizardVisible: true,
      };
   },
   computed: {
      isDemo() {
         return location.href.includes("/demo");
      },
      demoPrefix() {
         return this.isDemo ? "/demo/" : "/";
      }
   }
}
</script>
