<template>
   <div>
      <div class="container" id="validate">
         <qrscan />

      </div><!--END CONTAINER-->

   </div>
</template>

<script>

export default {
   name: "Validate",
   mounted() {
   },
   methods: {
   }
}
</script>
