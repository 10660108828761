<template>
   <div class="container">
      <div class="row" id="press-1">
         <div class="col-12 text-center mt-5">
            <h1>You've found a page that does not exist</h1>
            <hr>
            <h3 class="mt-4">Don't worry; the site is working correctly</h3>
            <h5><a class="blue01" href="/">Return to home screen</a></h5>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "Error404",
   data() {
      return {

      }
   }
}
</script>
