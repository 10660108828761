<template>
   <div class="container create-qr">
      <div class="row mt-3 mb-5 justify-content-md-center">
         <div class="col-sm-12 col-lg-6">
            <form>

               <div class="row mt-2" v-if="isServerApprovedChecked && !isServerApproved">
                  <div class="form-check form-check-sel-credit mb-4">
                     <label for="setSizeSelect">Select Credit</label>
                     <select class="form-control form-control-lg" id="setSizeSelect" v-model="maxShareSliceCountStr" style="min-width: 300px">
                        <option value="">(select)</option>
                        <option value="3" v-if="this.creditsRemaining[3] > 0">3-Slice Credit</option>
                        <option value="buy_3" v-if="this.creditsRemaining[3] == 0">3-Slice Credit (purchase)</option>
                        <option value="4" v-if="this.creditsRemaining[4] > 0">4-Slice Credit</option>
                        <option value="buy_4" v-if="this.creditsRemaining[4] == 0">4-Slice Credit (purchase)</option>
                        <option value="5" v-if="this.creditsRemaining[5] > 0">5-Slice Credit</option>
                        <option value="buy_5" v-if="this.creditsRemaining[5] == 0">5-Slice Credit (purchase)</option>
                        <option value="7" v-if="this.creditsRemaining[7] > 0">7-Slice Credit</option>
                        <option value="buy_7" v-if="this.creditsRemaining[7] == 0">7-Slice Credit (purchase)</option>
                        <option value="11" v-if="this.creditsRemaining[11] > 0">11-Slice Credit</option>
                        <option value="buy_11" v-if="this.creditsRemaining[11] == 0">11-Slice Credit (purchase)</option>
                     </select>
                  </div>

                  <div class="col-sm-12" v-if="maxShareSliceCountStr.includes('buy_')">

                     <button @click="goPurchaseCredit" class="btn btn-orange btn-lg btn-block" id="createQR" type="button">
                        Purchase
                     </button>
                  </div>

                  <div v-else>
                     <div class="col-sm-12 mb-4">
                        <div class="form-check">
                           <input type="checkbox" class="form-check-input" id="agreementTerms1" v-model="termsAndConditions" required>
                           <label class="form-check-label" for="exampleCheck1">
                              I agree to the
                              <a href="create-qr.html#" @click="$event.preventDefault()" data-toggle="modal" data-target="#termsModal" v-b-modal.termsModal>
                                 Terms &amp; Conditions
                              </a>
                           </label>
                        </div>
                     </div>
                     <div class="col-sm-12 mb-4">
                        <div class="form-check">
                           <input type="checkbox" class="form-check-input" id="agreementTerms2" v-model="agree24Hours" required>
                           <label class="form-check-label" for="exampleCheck1">
                              I have 24 hours to report an issue if I can't validate my QR Code set immediately after creation. 
                              <a href="create-qr.html#" @click="$event.preventDefault()" data-toggle="modal" data-target="#hoursModal" v-b-modal.hoursModal>
                                 Read More...
                              </a>
                           </label>
                        </div>
                     </div>

                     <div class="col-sm-12" v-if="maxShareSliceCountStr && termsAndConditions && agree24Hours && sufficientCredits">

                        <button @click="beginCreating" class="btn btn-orange btn-lg btn-block" id="createQR" type="button">
                           Next
                        </button>
                     </div>
                     <div class="col-sm-12" v-else>
                        <button class="btn btn-orange-disabled btn-lg btn-block" id="beginCreating" type="button">
                           Next
                        </button>
                        <div v-if="!sufficientCredits"> Insufficient Credits</div>
                     </div>
                  </div>
               </div>


               <div v-if="isServerApproved">
                  <div class="row justify-content-center">
                     <p v-if="isDemo" class="offline-note" style="color: #db0000; font-weight: 600; animation: fadeIn 1.5s;"><img width=30 :src="$store.state.offlineIconSvg" /> You may proceed online or offline</p>
                     <p v-else class="offline-note"><img width=30 :src="$store.state.offlineIconSvg" /> You may proceed online or offline</p>

                     <div id="tutorial-mobile-break" class="break" />
                     <button id="create-qr-tutorial-btn" class="btn-outline-secondary btn-sm" @click="$event.preventDefault(); startTutorial();">Tutorial</button>
                  </div>

                  <hr/>
                  <div class="set-name-1">
                     <label for="setName">Set Name (optional) </label>
                     <i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="Set names assist you in keeping track of your QR codes. They are printed directly above the QR code. NEVER include sensitive information in the set name."></i>
                     <input
                        type="text"
                        class="form-control form-control-lg"
                        id="setName"
                        aria-describedby=""
                        placeholder="Enter Set Name (e.g. Metamask)"
                        v-model="sliceName"
                        autofocus
                     >
                  </div>

                  <div class="row mt-4 threshold-1" v-if="!isDemo">
                     <div class="col-12">
                        <div class="row">
                           <div class="col">
                              <label for="setSize">Set Size</label>
                              <i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="The set size is the total number of slices (QR codes) that will be printed"></i>
                              <select class="form-control form-control-lg" id="setSize" v-model="shareCount">
                                 <option value="3" v-if="parseInt(maxShareSliceCountStr) >= 3">3-Slice</option>
                                 <option value="4" v-if="parseInt(maxShareSliceCountStr) >= 4">4-Slice</option>
                                 <option value="5" v-if="parseInt(maxShareSliceCountStr) >= 5">5-Slice</option>
                                 <option value="6" v-if="parseInt(maxShareSliceCountStr) >= 6">6-Slice</option>
                                 <option value="7" v-if="parseInt(maxShareSliceCountStr) >= 7">7-Slice</option>
                                 <option value="8" v-if="parseInt(maxShareSliceCountStr) >= 8">8-Slice</option>
                                 <option value="9" v-if="parseInt(maxShareSliceCountStr) >= 9">9-Slice</option>
                                 <option value="10" v-if="parseInt(maxShareSliceCountStr) >= 10">10-Slice</option>
                                 <option value="11" v-if="parseInt(maxShareSliceCountStr) >= 11">11-Slice</option>
                              </select>
                           </div>

                           <div class="col">
                              <label for="thresholdSize">Threshold Size</label>
                              <i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="The threshold size is the number of slices (QR codes) required to retrieve the information stored in a set"></i>
                              <select class="form-control form-control-lg" id="thresholdSize" v-model="shareThreshold">
                                 <option value="0">(select)</option>
                                 <option value="2">2-Slice</option>
                                 <option value="3" v-if="shareCount > 3">3-Slice</option>
                                 <option value="4" v-if="shareCount > 4">4-Slice</option>
                                 <option value="5" v-if="shareCount > 5">5-Slice</option>
                                 <option value="6" v-if="shareCount > 6">6-Slice</option>
                                 <option value="7" v-if="shareCount > 7">7-Slice</option>
                                 <option value="8" v-if="shareCount > 8">8-Slice</option>
                                 <option value="9" v-if="shareCount > 9">9-Slice</option>
                                 <option value="10" v-if="shareCount > 10">10-Slice</option>
                              </select>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="row mt-4 threshold-1" v-if="isDemo">
                     <div class="col-12">
                        <div class="row">
                           <div class="col">
                              <label for="setSize">Set Size</label>
                              <i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="The set size is the total number of slices (QR codes) that will be printed"></i>
                              <select class="form-control form-control-lg" id="setSize" v-model="shareCountDemo" @change="clickDemoAltSliceCount">
                                 <option value="3">3-Slice</option>
                                 <option value="4">4-Slice (upgrade)</option>
                                 <option value="5">5-Slice (upgrade)</option>
                                 <option value="7">7-Slice (upgrade)</option>
                                 <option value="11">11-Slice (upgrade)</option>
                              </select>
                           </div>

                           <div class="col">
                              <label for="thresholdSize">Threshold Size</label>
                              <i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="The threshold size is the number of slices (QR codes) required to retrieve the information stored in a set"></i>
                              <select class="form-control form-control-lg" id="thresholdSize" v-model="shareThreshold">
                                 <option value="2">2-Slice</option>
                              </select>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="row mt-3 confidential-1">
                     <div class="col-lg-12">
                        <div class="">
                           <label for="seedPhrase">
                              <span class="">Enter Confidential Information</span>
                           </label>
                           <i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="The confidential information entered below will be encrypted across multiple QR codes. The information will only be recoverable by scanning the threshold number of QR codes."></i>
                           <textarea
                              class="form-control secretField"
                              id="seedPhrase"
                              rows="4"
                              placeholder="Enter seed phrase or other confidential information"
                              v-model="seedText"
                              :maxlength="maximumCharacterLength"
                              required
                           >
                           </textarea>
                           <div class="text-right" v-if="!isDemo">
                              <p v-if="maximumCharacterLength - characterLength >= 0">
                                 <span id="counter">Remaining: {{maximumCharacterLength - characterLength}}</span>
                              </p>
                              <p v-else>
                                 <span id="counter" style="color: red;">Remaining: {{maximumCharacterLength - characterLength}}</span>
                              </p>
                           </div>
                           <div class="text-right" v-else>
                              <p v-if="maximumCharacterLength - characterLength > 0">
                                 <span id="counter">Remaining: {{maximumCharacterLength - characterLength}}</span>
                              </p>
                              <p v-else>
                                 <span id="counter" style="color: red;">Demo allows for maximum 30 characters<br/><a href="#" @click="clickDemoPurchase()"><b style="color: red">Upgrade</b></a> for 2,000 characters</span>
                              </p>
                           </div>
                           <div class="row">
                              <div class="col">
                                 <select class="form-control form-control-lg" id="printScope" v-model="printScope">
                                    <option value="full">Print Full Set</option>
                                    <option value="individual">Print QR Codes Individually</option>
                                 </select>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="mt-3" v-if="shareThreshold && ((maximumCharacterLength - characterLength) >= 0) && (seedText !== '')">
                     <button @click="createQrCode" class="mt-5 btn btn-orange btn-lg btn-block" id="createQR2" type="button">
                        Create QR Codes
                     </button>
                  </div>
                  <div v-else class="mt-3">
                     <button @click="$event.preventDefault()" class="mt-5 btn btn-orange-disabled btn-lg btn-block" id="createQR2" type="button">
                        Create QR Codes
                     </button>
                  </div>

               </div>


            </form>

            <!-- Modal -->
            <b-modal class="modal fade" id="termsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" centered hide-footer>
               <template #modal-title>Terms &amp; Conditions</template>

               <div class="modal-content">
                <div class="modal-body">
                  <h2 class="text-center">Terms of Use</h2>
                  <br>
                  <h3>Use of Xecret.io</h3>
                  <p>Welcome to Xecret.IO (“Xecret”, “We,” “Us,” “Website”).  Please read our terms and conditions carefully because it contains legal obligations regarding
                  your use of this Website and Xecret’s services (including limitations on liability, arbitration provisions and a class action waiver among other terms).</p>

                  <br>
                  <h3>1. Acceptance</h3>
                  <p>We offer this Website and its services (“Services”) conditioned upon your being eighteen years or older and your agreement to be bound by and subject to
                  these Terms of Service (and our Privacy Policy) unless separate terms are offered by you in writing and accepted in writing by Us.</p>

                  <p>We reserve the right, in our sole discretion, to change these Terms of Service at any time by posting revised terms on the site. Notice of these changes will be posted on the
                    homepage of this Website and the revised Terms of Service will take effect seven (7) days after their publication on the Service.  In the event we make material changes to the
                    Terms of Service we will also notify you via email at the address we have on file. Your continued use of or access of this website following the posting of any changes
                    constitutes acceptance of those changes.  For your information, these terms were last updated on the date listed in the table below.</p>

                  <p>Should you become aware of any violations of these Terms of Service and/or related guidelines, policies or rules please report them to <a href="contact.html">our contact form</a>.</p>

                  <br>
                  <h3>2. Your Account</h3>

                  <p>You agree to provide current, complete and accurate purchase and account information for all purchases made.  You agree to promptly update your account and other information,
                  including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed (where contact information is provided). </p>

                  <p>You agree that you are entirely responsible for the security of your login and password and for all aspects of keeping your account secure. Furthermore, you are entirely responsible for
                    any and all activities that occur on your account. You agree to notify us immediately of any unauthorized access to or use of your username or password or any other breach of security.
                    We will not be liable for any loss that you may incur as a result of someone else using your password or account, either with or without your knowledge. However, you can be held liable
                    for losses incurred by us or another party due to someone else using your account or password</p>

                  <p>We have the right to disable any user name, password or other identifier at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any
                  provision of these Terms of Service or engaged in any usage of the Services that We determine in our sole discretion to be suspect.</p>

                  <br>
                  <h3>3. Compliance with Law</h3>

                  <p>In connection with the performance, access and use of the Services, each party agrees to comply with all applicable laws, rules and regulations including, but not limited to export, privacy,
                  and data protection laws and regulations. Each party represents that it is not named on any U.S. government denied-party list. Further, neither you nor your users shall access or use any of
                  the Services or content in a U.S. embargoed country or in violation of any U.S. export law or regulation. If necessary and in accordance with applicable law, we will cooperate with local, state,
                  federal and international government authorities with respect to the Services. </p>

                  <p>You agree not to (i) modify, prepare derivative works of, or reverse engineer, our Services; (ii) knowingly or negligently use our Services in a way that abuses or disrupts our networks, user accounts,
                  or the Services; or (iii) use the Services to commit fraud or impersonate any person or entity or otherwise impairs the legal rights of others.</p>

                  <br>
                  <h3>4. Intellectual Property</h3>

                  <p>All content on this Website, and the (Software) Services are the exclusive property of and owned by Xecret, its licensors or its content providers and is protected by copyright, trademark and other
                    applicable laws. You may access, copy, download and print the material contained on the site for your personal non-commercial use, provided you do not modify or delete any copyright, trademark or other
                    proprietary notice that appears on the material you access, copy, download or print. Any other use of content on the site, including but not limited to the modification, distribution, transmission,
                    performance, broadcast, publication, uploading, licensing, reverse engineering, transfer or sale of, or the creation of derivative works from, any material, information, software, products or services
                    obtained from the site, or use of the site for purposes competitive to Xecret, is expressly prohibited. Xecret, or its licensors or content providers, retain full and complete title to the material
                    provided on the site, including all associated intellectual property rights, and provide this material to you under a license that is revocable at any time in our sole discretion. </p>

                    <p>Trademarks, logos and service marks displayed on the website and Services are registered and unregistered trademarks of Xecret, its licensors or content providers, or other third parties. All
                      other trademarks, logos and service marks are the property of their respective owners. Nothing on the website or the Service shall be construed as granting, by implication, estoppel, or otherwise,
                      any license or right to use any trademark, logo or service mark displayed on the site without the owner's prior written permission, except as otherwise described herein. Xecret reserves all rights
                      not expressly granted in and to the site and its content. This site and all of its content, including but not limited to text, design, graphics, logos, icons, images, audio clips, downloads,
                      interfaces, code and software along with the selection and arrangement thereof, is protected as a compilation under the copyright laws of the United States and other countries.</p>

                    <p>Pursuant to the Digital Millennium Copyright Act (DMCA) Safe Harbor (17 U.S.C. § 512(c), if you believe that anything on the Service or service offered therein infringes any copyright that you own or
                      control, you may file a notice of such infringement, in compliance with the requirements of 17 U.S.C. § 512(c)(3), with our designated agent: [insert mailing and email address]. </p>

                      <br>
                      <h3>5. Links</h3>

                      <p>This Website may provide links to external sites as a convenience to the user. The inclusion of any link does not imply our endorsement of any other company, its site, or the products and services it
                        sells. Xecret is not responsible for the privacy practices or the content of any other Website. </p>

                      <p>Please contact Xecret for prior permission if you wish to create a hypertext link to any page on this Webite. If you create a link to the Website, you must do so in a way that is fair and legal and does
                      not damage our reputation or take advantage of it or suggest any form of association, approval or endorsement on our part without our express written consent.   You remain fully responsible for any consequences
                      of that link, whether direct or indirect, and you will protect Xecret against all loss, damage, liability, costs, or expense arising from or in connection with the link.</p>

                      <br>
                      <h3>6. Orders</h3>

                      <p>The information on the Website does not constitute a binding offer to sell the Services described on the Website in your area. We reserve the right at any time after receipt of your order to accept or decline
                      your order, or any portion thereof, in our sole discretion, even after your receipt of an order confirmation or after your credit card has been charged or crypto-currency payment received. In the event that we
                      make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made.</p>

                      <p>The prices displayed on the site are quoted in U.S. dollars and are intended to be valid and effective only in the United States. In the event a product is listed at an incorrect price, we have the right to
                      refuse or cancel orders placed for the product listed at the incorrect price, regardless of whether the order has been confirmed or your credit card charged. If your credit card has already been charged for
                      the purchase and your order is canceled, we will issue a credit to your credit card account.</p>

                      <p>Prices for the Services are subject to change without notice.  We reserve the right at any time to modify or discontinue the Services or any part or content thereof) without notice at any time.</p>

                      <br>
                      <h3>7. Payment</h3>

                      <p>You agree to pay all applicable fees for the Services on the terms set forth in these Terms of Service. Any and all payments you make to us for access to the Services are final and non-refundable. You are
                        responsible for all fees and charges you incur to your other service providers (e.g. transaction fees, your broadband and internet provider) in connection with your use of the Services. You agree that we
                        may charge your payment card, your wallet or bill you for all amounts due for your use of the Services, and we may take steps to update your payment card information (where permitted) to ensure that payment
                        can be processed. You agree that your credit information and related personal data may be provided to third parties for payment processing and fraud prevention purposes. We may, where permitted by applicable
                        law, suspend or terminate your Services if at any time we determine that your payment information is inaccurate or not current, and you are responsible for fees and overdraft charges that we may incur when we
                        charge your card for payment. </p>

                      <p>When you purchase the Services on a subscription basis, you agree that you are authorizing recurring payments, and payments will be made to Xecret by the method and at the recurring intervals you have agreed to,
                        until the subscription for that Service is terminated by you or by Xecret. You must cancel your Services Agreement before the next billing date to stop being charged to continue your Services. By canceling your
                        Service Agreement, you are also terminating your services, effective immediately. No credits will be provided for unused time. By authorizing recurring payments, you are authorizing Xecret to store your payment
                        instrument and process such payments as either electronic debits or fund transfers, or as electronic drafts from your designated account (for Automated Clearing House or similar payments), or as charges to your
                        designated account (for credit card or similar payments) (collectively, "Electronic Payments''). Subscription fees are generally charged in advance of the applicable subscription period. If any payment is returned
                        unpaid or if any credit card or similar transaction is rejected or denied, Xecret or its service providers reserve the right to collect any applicable return item, rejection or insufficient funds fee and process
                        any such payment as an Electronic Payment.</p>

                      <p>You are responsible for all applicable transaction fees in addition to any sales, services, value-added, goods and services, withholding, tariffs, or any other similar fees as may be applicable in the location
                        in which the Services are being provided and similar taxes or fees (collectively, “Taxes and Fees”) imposed by any government entity or collecting agency based on the Services, except those Taxes and Fees based
                        on our net income, or Taxes and Fees for which you have provided an exemption certificate. In all cases, you will pay the amounts due under these Terms of Service to us in full without any right of set-off or
                        deduction.</p>

                      <p>Unless otherwise provided by law, all purchases are final and non-refundable. If you believe that Xecret has charged you in error, you must contact us within 30 days of such charge. No refunds will be given for
                        any charges more than 30 days old. We reserve the right to issue refunds or credits at our sole discretion. If we issue a refund or credit, we are under no obligation to issue the same or similar refund in the future.</p>

                      <br>
                      <h3>8. Limitation of Warranties and Liability</h3>

                      <p>YOUR USE OF THIS WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. XECRET’S CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN “AS IS'' AND
                        “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS.
                        XECRET DISCLAIMS ANY WARRANTY THAT THE WEBSITES, OR YOUR ACCESS TO THE XECRET SITES, WILL BE UNINTERRUPTED, SECURE, RELIABLE, TIMELY OR ERROR-FREE. XECRET DOES NOT REPRESENT OR WARRANT THE ACCURACY, CONTENT, COMPLETENESS,
                        LEGALITY, RELIABILITY, OPERABILITY, OR AVAILABILITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR MATERIAL DISPLAYED OR DOWNLOADED FROM THE WEBSITES. YOUR USE OF THIS WEBSITE, INCLUDING IF YOU DOWNLOAD OR OTHERWISE OBTAIN PRODUCTS,
                        MATERIALS OR DATA THROUGH THE USE OF THE WEBSITE, SERVICES, OR PRODUCTS, IS AT YOUR OWN DISCRETION AND RISK. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM USE OF THE
                        XECRET SITES, SERVICES, OR PRODUCTS OR THE DOWNLOAD OF SUCH PRODUCT, MATERIAL OR DATA. IF THE JURISDICTION WHERE YOU ARE LOCATED LIMITS THE EXCLUSION OF WARRANTIES, THEN THE ABOVE EXCLUSIONS APPLY TO THE FULLEST EXTENT AVAILABLE
                        UNDER THE LAW.</p>

                      <p>UNDER NO CIRCUMSTANCES IS XECRET, ITS AFFILIATES, SUBSIDIARIES OR LICENSORS LIABLE TO YOU FOR ANY DIRECT OR INDIRECT DAMAGES WHATSOEVER (INCLUDING, BUT NOT LIMITED TO, INCIDENTAL, RELIANCE, CONSEQUENTIAL, PUNITIVE OR SPECIAL
                        DAMAGES WHETHER OR NOT FORESEEN, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS, LOSS OF USE, LOSS OF GOODWILL, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS
                        INTERRUPTION) RELATED TO OR ARISING FROM THE XECRET WEBSITE OR ON ACCOUNT OF YOUR USE, MISUSE, OR RELIANCE ON THE INFORMATION, SERVICES OR PRODUCTS AVAILABLE ON THE XECRET WEBSITES, INCLUDING WEBSITES LINKED TO OR ACCESSED
                        FROM THE XECRET WEBSITES AND ANY PRODUCTS DOWNLOADED FROM, OR SERVICES ACCESSED THROUGH THE XECRET WEBSITES. THIS LIMITATION OF LIABILITY SHALL APPLY WHETHER SUCH CLAIM IS BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE),
                        OR ANY OTHER LEGAL THEORY (EVEN IF XECRET HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES). THIS LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT OF APPLICABLE LAW.</p>

                      <p>EXCEPT FOR YOUR INDEMNIFICATION OBLIGATIONS BELOW, AND TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE TOTAL CUMULATIVE LIABILITY OF EITHER PARTY AND THEIR RESPECTIVE LICENSORS AND SUPPLIERS ARISING OUT OF THIS AGREEMENT IS LIMITED
                        TO THE SUM OF THE AMOUNTS PAID FOR THE APPLICABLE SERVICE DURING THE 12 MONTHS IMMEDIATELY PRECEDING THE INCIDENT GIVING RISE TO THE LIABILITY. THE FOREGOING DOES NOT LIMIT YOUR OBLIGATIONS TO PAY ANY UNDISPUTED FEES AND OTHER AMOUNTS
                        DUE UNDER ANY ORDER.</p>

                        <br>
                        <h3>9. Indemnity</h3>

                        <p>You agree to defend, indemnify and hold harmless XECRET, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns
                          from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Service or your use of the
                          XECRET Website.</p>

                          <br>
                          <h3>10. Consent to Electronic Communications and Language</h3>

                          <p>Xecret may send you notifications, notices, or links, via your email, phone, text messages, messages to your account, or via your mobile device.  By using the Services, you agree to receive these notifications.   The types of notifications
                            you may receive include changes to these Terms, invoices or records of payment, marketing communications, and any number of other communications related to our Services.  Any notification is considered "in writing," regardless of whether it
                            is in a paper format, a digital format, or some other format.</p>

                          <p>For Residents of Québec / Pour les résidents du Québec. The parties declare that they have required that these Terms of Service and all documents related hereto, either present or future, be drawn up in the English language only. / Les parties
                            déclarent par les présentes qu’ils exigent que cette entente et tous les documents y afférents, soit pour le présent ou l’avenir, soient rédigés en langue anglaise seulement.</p>

                          <p>Where Xecret has provided a translation of the English-language version of a the Website, these Terms of Use, or other materials, you agree that (i) the translations are only for your convenience; (ii) the English-language version governs your
                          use of the materials provided; and (iii) the English-language version shall take precedence in the event of a conflict between the English-language version and the translated version (except as prohibited by local law).</p>

                          <br>
                          <h3>11. Foreignn Users</h3>

                          <p>Xecret makes no representation that materials on this Website are appropriate or available for use in other locations. If you access this website from outside the United States, please understand that this website may contain references and/or links
                            to products and services that are not available or are prohibited in your jurisdiction.  Any user who is a resident of a foreign country agrees that (i) they have voluntary sought and established contact with Xecret, (ii) they will not use, transmit,
                            disseminate or upload any material, content, that would violate any applicable local, state or national laws or regulations of the foreign member’s country of residence; (iii) under no circumstance shall Xecret be deemed liable under any laws other
                            than the United States; (iv) his or her participation is governed by Florida law and subject to the arbitration and venue provisions stated herein; and (v) consent to having their data processed in the United States.</p>

                            <br>
                            <h3>12. Dispute Resolution</h3>

                            <p>These Terms of Use shall be governed by the laws of the State of Florida without reference to its conflict of laws principles (but excluding the United Nations Convention on Contracts for the International Sale of Goods).</p>

                            <p>Any dispute, controversy or other claim arising out of this Agreement shall be resolved by arbitration conducted in English in accordance with the Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes of the
                            American Arbitration Association ("AAA"), as modified by this section. Disputes will be arbitrated only on an individual basis and will not be consolidated with any other arbitrations or other proceedings that involve any claim or controversy of
                            any other party. But if, for any reason, any court with competent jurisdiction or any arbitrator selected pursuant to this section holds that this restriction is unconscionable or unenforceable, then our agreement to arbitrate will not apply and
                            the Dispute must be brought exclusively in the state and federal courts serving either Dade County, Florida or Los Angeles County, California</p>

                            <p>For any claim where the total amount of the award sought is $10,000 or less: (a) the arbitration shall be conducted solely based on telephone or online appearances and/or written submissions; and (b) the arbitration shall not involve any personal
                              appearance by the parties or witnesses unless otherwise mutually agreed by the parties. If the claim exceeds $10,000, the right to a hearing will be determined by the AAA rules, and the hearing (if any) must take place in either Miami, Florida or
                              Los Angeles, California at our discretion.  You hereby consent to such exclusive venue and jurisdiction of the AAA and state and federal courts serving Dade County, Florida and Los Angeles County, California.  Unless otherwise agreed by the parties,
                              the arbitration panel shall consist of one arbitrator chosen in accordance with the rules of the AAA.  Any such arbitrator shall be knowledgeable in the subject area in which the dispute arises.  All discovery shall be completed within sixty (60)
                              days following the appointment of the arbitrator.  Each party shall be entitled to representation by counsel, to appear and present written and oral evidence and argument and to cross-examine witnesses presented by the other party, provided that,
                              where appropriate, the arbitrator may receive testimony via telephone, video or other electronic means of communication.  The arbitration award shall be in writing and the arbitrator shall provide written reasons for the award.  The award of the
                              arbitrator shall be final and binding on the parties hereto and may be enforced in any court of competent jurisdiction.  For purposes hereof, prevailing party is defined as the party whose last settlement proposal prior to hearing in this matter
                              is closest to the final decision. In no event shall the arbitrator award punitive or exemplary damages. The parties waive any right they may have to an appeal of the arbitrator’s decision and/or award.  Each party retains the right to seek judicial
                              assistance: (i) to compel arbitration, (ii) to obtain interim measures of protection prior to or pending arbitration, (iii) to seek injunctive relief in the courts of any jurisdiction as may be necessary and appropriate to protect the unauthorized
                              disclosure of its proprietary or confidential information; (iv) for any claims of infringement or misappropriation of any patent, copyright, trademark, or trade secrets; and (v) to enforce any decision of the arbitrator, including the final award.</p>

                            <p>For all disputes you may have, whether pursued in court or arbitration, you must first give Xecret an opportunity to resolve the dispute by providing written notification to [insert email address] and via postal mail to [insert address] stating (i)
                              your name, (ii) your address, (iii) a written description of your Claim, and (iv) a description of the specific relief you seek. If Xecret does not resolve the Dispute within 30 days after it receives your written notification, you may pursue your
                              dispute as set forth above.</p>

                            <p>IN ADDITION, ANY CLAIMS AGAINST XECRET MUST BE BROUGHT WITHIN ONE (1) YEAR FROM THE CLAIM AROSE OR IT IS PERMANENTLY BARRED.</p>

                            <br>
                            <h3>13. General Terms</h3>

                            <p>This Agreement constitutes the entire agreement between Xecret and you concerning your use of the Website and supersedes all prior agreements. If any part of this Agreement is held invalid or unenforceable, that part will be construed to reflect the
                              parties’ original intent, and the remaining portions will remain in full force and effect. A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition or any
                              subsequent breach thereof.</p>

                            <p>Either party to this agreement shall be excused from any delay or failure in performance hereunder caused by reason of any occurrence or contingency beyond its reasonable control, including but not limited to, acts of God, earthquake, labor disputes and strikes, riots, war, and governmental requirements. The obligations and rights of the party so excused shall be extended on a day-to-day basis for the period of time equal to that of the underlying cause of the delay.</p>

                            <p>You may not assign or transfer any rights under these Terms of Use without Xecret’s prior written consent.  Subject to the foregoing, these Terms of Use will bind and inure to the benefit of the parties, their successors, and permitted assigns.</p>

                            <p>Xecret reserves the right, at any time, to modify, suspend, discontinue or terminate all or any part of the Services offered without notice at any time. Xecret may impose limitations on website usage, including, without limitation, bandwidth used by any users; the number and duration of log-ins; and/or terminate inactive accounts. You agree that your use the Services is at your own risk and that Xecret has no responsibility or liability for your inability to use the service or access any content.</p>

                            <p>Xecret welcome your feedback about the Website.  Any comments, ideas, notes, messages, suggestions, or other communications sent, however, shall be and remain the exclusive property of Xecret and we may use all such communications and content in any manner, including reproducing, disclosing, and publishing such communications, all without compensation to you.</p>

                            <br>
                            <h3>14. Consumer Complaints</h3>

                            <p>Under California Civil Code Section 1789.3, California Website users are entitled to know that they may file grievances and complaints with: the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs, in writing at 400 R Street, Suite 1080, Sacramento, California 95814, or by telephone at (916) 445-1254 or (800) 952-5210, or by email at dca@dca.ca.gov.</p>

                            <br>
                            <h3>15. Contact</h3>

                            <p>Questions about the Terms of Service should be sent to <a href="contact.html">our contact form</a></p>

                            <br>
                            <h3>NOTICE OF ARBITRATION AGREEMENT:</h3>

                            <p>This agreement provides that all disputes between you and Xecret will be resolved by BINDING ARBITRATION. You thus GIVE UP YOUR RIGHT TO GO TO COURT to assert or defend your rights under this contract (EXCEPT for matters that may be taken to SMALL CLAIMS COURT).</p>

                            <ul>
                              <li>Your rights will be determined by a NEUTRAL ARBITRATOR and NOT a judge or jury.</li>
                              <li>You are entitled to a FAIR HEARING, BUT the arbitration procedures are SIMPLER AND MORE LIMITED THAN RULES APPLICABLE IN COURT.</li>
                              <li>Arbitrator decisions are as enforceable as any court order and are subject to VERY LIMITED REVIEW BY A COURT.</li>
                              <li>FOR MORE DETAILS</li>
                            </ul>

                            <p>Review Dispute Resolution section above, or visit <a href="http://www.adr.org/consumer_arbitration">http://www.adr.org/consumer_arbitration</a>.</p>


                </div>
              </div>
            </b-modal>
            <b-modal class="modal fade" id="hoursModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" centered hide-footer>
               <template #modal-title></template>
               <div class="modal-body text-center">
                  <p>In rare circumstances, you may not be able to successfully validate your QR code set. If you encounter such issue, you have 24 hours to report it, from the time you "Use Credit". To report it, please refer to "Unable to validate link" under the help section.</p>
               </div>
            </b-modal>


            <PurchasePopup 
               v-if="showPurchasePopup"
               @hidePurchasePopup="hidePurchasePopup()"
               >
            </PurchasePopup>


            <v-tour name="createQrTour" :steps="createQrTourSteps" :callbacks="tourCallbacks"></v-tour>
         </div>
      </div><!--END ROW-->
   </div><!--END CONTAINER-->
</template>
<script>
import AXorIda from '@/xecret-ida/xorida';

import documentTemplate from "@/assets/img/xecret-share-template.svg";

import PurchasePopup from "@/components/purchase-popup"

export default {
   components: {
      PurchasePopup,
   },
   data() {
      return {
         creditsRemaining: {},
         maxShareSliceCountStr: "",
         shareThreshold: 0,
         shareCount: 3,
         sliceName: "",
         seedText: "",
         termsAndConditions: false,
         agree24Hours: false,
         sufficientCredits: true,
         isServerApproved: false,
         isServerApprovedChecked: false,
         printScope: "full",
         shareCountDemo: 3,
         showPurchasePopup: false,
         tourCallbacks: {
            onSkip: this.handleTourEnded,
            onFinish: this.handleTourEnded,
            onStop: this.handleTourEnded,
         },
         createQrTourSteps: [
            {
               target: '#setName',
               content: `Set names assist you in keeping track of your QR codes. They are printed directly above the QR code. NEVER include sensitive information in the set name.`
            },
            {
               target: '#setSize',
               content: `The set size is the total number of slices (QR codes) that will be printed`
            },
            {
               target: '#thresholdSize',
               content: `The threshold size is the number of slices (QR codes) required to retrieve the information stored in a set`
            },
            {
               target: '#seedPhrase',
               content: `The confidential information entered below will be encrypted across multiple QR codes. The information will only be recoverable by scanning the threshold number of QR codes.`
            },
            {
               target: '#printScope',
               content: `You can print all three of your QR codes as one single print job, or you can print them individually one at a time or get a digital copy one at a time. We strongly recommend against digitally transmitting your QR codes for security reasons.`,
            }
         ]
      }
    },
   computed: {
      characterLength() {
         // Current number of utf-8 encoded bytes
         return new TextEncoder("utf-8").encode(this.seedText).length;
      },
      isDemo() {
         return location.href.includes("/demo");
      },
      demoPrefix() {
         return this.isDemo ? "demo/" : "";
      },
      maximumCharacterLength() {
         return this.isDemo ? 30 : 2000;
      }

   },
   mounted() {
      let that = this;
      if (this.isDemo) {
         this.maxShareSliceCountStr = "3";
         this.isServerApproved = true;
         this.beginCreating();
      }
      else {
         this.axios
            .get("/api/account-data", { withCredentials: true })
            .then((response) => {
               console.log(response);
               that.$store.commit("setAccountData", response.data);
               that.creditsRemaining = response.data.credits_remaining;
               let foundAnySubscriptions = false;
               for (let key in response.data.unlimited_subscription_slice_counts) {
                  let value = response.data.unlimited_subscription_slice_counts[key];
                  that.creditsRemaining[value] = 999;
                  that.maxShareSliceCountStr = `${value}`;
                  that.isServerApproved = true;
                  that.beginCreating();
                  foundAnySubscriptions = true;
               }
               if (!foundAnySubscriptions) {
                  this.isServerApprovedChecked = true;
               }
               console.log(that.creditsRemaining);
            })
            .catch((error) => console.error(error));
      }
   },
   methods: {
      handleTourEnded() {
         this.$store.commit("setIsCreateQrTourRun", true);
         this.scrollWindowToTop();
      },
      goPurchaseCredit() {
         let sliceCount = this.maxShareSliceCountStr.replace("buy_", "");
         window.location.assign(`/payment?sliceCount=${sliceCount}`);
      },
      clickDemoAltSliceCount() {
         if (this.shareCountDemo != 3) {
            this.clickDemoPurchase();
         }
      },
      hidePurchasePopup() {
         this.showPurchasePopup = false;
      },
      clickDemoPurchase() {
         this.showPurchasePopup = true;
      },
      startTutorial() {
         this.$tours['createQrTour'].start();
      },
      scrollWindowToTop() {
         let totalMillisec = 300;
         let stepMillisec = 15;
         let scrollStep = -window.scrollY / (totalMillisec / stepMillisec),
         scrollInterval = setInterval(function() {
            if (window.scrollY != 0) {
               window.scrollBy(0, scrollStep);
            }
            else {
               clearInterval(scrollInterval); 
               document.getElementById("setName").focus();
            } 
         }, stepMillisec);
      },
      beginCreating() {
         console.log(this.maxShareSliceCountStr);
         this.$store.commit("setIsHidingNavigateAway", true);
         fetch(documentTemplate)
            .then(res => res.text())
            .then(svgText =>  {
               this.$store.commit("setPageTemplate", svgText);
            });

         let maxSliceCount = parseInt(this.maxShareSliceCountStr);
         this.shareCount = maxSliceCount;
         this.shareThreshold = (this.shareCount === 3) ? 2 : 0;

         if (this.isDemo) {
            if (!this.$store.state.isCreateQrTourRun) {
               this.startTutorial();
            }
         } else {
            this.axios
               .post("api/ensure-active-credit", { maxSliceCount: maxSliceCount }, { withCredentials: true, })
               .then((response) => {
                  console.log(response);
                  this.sufficientCredits = response.data.have_active_credit;
                  this.$store.commit("setActiveCreditId", response.data.credit_id);
                  if (this.sufficientCredits)
                  {
                     this.isServerApproved = true;

                     this.axios
                        .get("/api/account-data", { withCredentials: true })
                        .then((response) => {
                           console.log(response);
                           this.$store.commit("setAccountData", response.data);
                           this.creditsRemaining = response.data.credits_remaining;
                           for (let key in response.data.unlimited_subscription_slice_counts) {
                              let value = response.data.unlimited_subscription_slice_counts[key];
                              this.creditsRemaining[value] = 999;
                           }
                           console.log(this.creditsRemaining);
                        })
                        .catch((error) => console.error(error));
                  }
               })
               .catch((error) => console.error(error));
         }
         if (this.isDemo) {
            window.gtag("event", "Demo/Create/Begin", {max_slices: this.maxShareSliceCountStr});
         }
         else {
            window.gtag("event", "Paid/Create/Begin", {max_slices: this.maxShareSliceCountStr});
         }
      }, // end beginCreating()

      createQrCode() {
         this.axios
            .post("/api/usage-counter", { }, { withCredentials: true, })
            .then(() => {
               // ignore response
            })
            .catch((error) => console.error(error));

         this.$store.commit('setUUID');

         if (this.isDemo) {
            this.shareCount = 3;
            this.shareThreshold = 2;
         }

         // Create shares based on inputed text, store the shares, slice name and uuid in Vuex
         const ida = new AXorIda(this.shareThreshold, this.shareCount, 128);
         const secret = new TextEncoder("utf-8").encode(this.seedText);
         const allShares = ida.SplitSecret(secret);

         this.$store.commit("setPrintScope", this.printScope);
         this.$store.commit('setShareThreshold', this.shareThreshold);
         this.$store.commit('setShareCount', this.shareCount);
         this.$store.commit('setShares', allShares);
         this.$store.commit('setName', this.sliceName);

         this.$router.push({path: `/${this.demoPrefix}print-qr`});
         if (this.isDemo) {
            window.gtag("event", "Demo/Create/Complete");
         }
         else {
            window.gtag("event", "Demo/Create/Complete");
         }
      }
   }
};
</script>